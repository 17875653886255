:root {
    --color_bg: rgba(255, 255, 255, 0);
    --color_bg_fade: #007bffa2;
    --color_bg_fade_4x: #007bff23;
    --color_nav_bg: rgba(255, 255, 255, 1);
    --color_font: black;
    --color_fonthover: #007bff;
    --color_hamburger: black;
    --color_hamburgerhover: #007bffa2;
    --color_button: #007bffa2;
    --color_button_hover: white;
    --color_blue: #007bff;
    --color_oj: #f8960eff;
    --color_oj_dark: rgb(196, 114, 0);
    --color_green: #207c35;
    --color_dark_green: #195c28;
    --color_dark_blue: #0e5c91;
    --color_darker_blue: #0c3f61;
    --color_purple: #8884d8;
    --color_white: #ffffff;
    --color_menu_resp_bg: rgb(255, 255, 255);
    --color_grey_bg: #343434;
    --color_grey_light: rgb(177, 177, 177);
    --color_grey_dark: rgb(114, 114, 114);
    --color_grey: rgb(218, 214, 214);
    --color_grey_transparent: rgba(177, 177, 177, 0.5);
    --font_logo: normal normal normal 50px/1.4em poppins-semibold, poppins, sans-serif;
    --font_text: normal normal normal 18px/1.4em montserrat, sans-serif;
    --navbar_vh_p: 93px;
    --navbar_vh: 10vh;
    --navbar_maxwidth: 95vw;
    --navbar_maxwidth_mob: 85vw;
    --footer_vh: 40px;
    --countdown_index: 50;
    --navbar_index: 25;
    --footer_index: 25;
    --lobby_index: 5;
    --main_vh: 300px;
    --main_vh_max: 85vh;
    --dialog_z1: 50;
    --others: 5;
    --side_menu: 55px;
    --side_menu_open: 180px;
    --mobile_width: 1000px;
    --shadow-color: hsla(210, 40%, 52%, .4);
    --btn-color: hsl(210, 80%, 42%);
    --bg-color: #141218;
    --mini_navbar_bg: #ffffffd8;
    --table_row_blue: #228CFF;
    --z_index_terms: 100;
}

header {
    z-index: 40;
}

body {
    overflow-x: hidden;
}

a,
div,
main,
header,
footer,
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
    font-family: "Poppins", sans-serif;
}

a {
    outline: none;
    color: var(--color_font);
    text-decoration: none;

    &:hover {
        color: var(--color_fonthover);
    }

    &.active {
        // color: var(--color_oj);
    }
}

.superscript {
    display: inline;
    position: relative;
    top: -0.5rem;

    font-size: min(0.5rem, 1.2vh);

    &.smaller {
        top: -0.3rem;
        font-size: 0.5rem;
    }
}

.app_img {
    position: fixed;
    max-width: 100px;
    right: 0;
    margin: 50px;
}

.flexrow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
}

.flexcol {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
}

.flexcenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nowrap {
    flex-wrap: nowrap !important;
}

.clickable {
    cursor: pointer;
}

.avoid-clicks {
    pointer-events: none;
}

.margin-auto {
    margin: auto !important;
}

.margin-vauto {
    margin: auto 0px !important;
}

.margin-hauto {
    margin: 0px auto !important;
}

.mobile-hide {
    @media screen and (max-width: 1000px) {
        display: none !important;
    }
}

.text_left {
    text-align: left;
}

.button {
    color: white;
    border-radius: 0.5rem;
    border: 2px solid var(--color_button);
    background-color: var(--color_button);
    text-align: center;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        color: var(--color_button);
        background-color: var(--color_button_hover);
        border: 2px solid var(--color_button);
    }

    &.selected {
        color: white !important;
        background-color: var(--color_darker_blue) !important;
    }

    &.disabled {
        pointer-events: none;
        background-color: rgba(116, 116, 116, 0.322);
    }
}

input {
    border: 2px solid var(--color_button);
    border-radius: 0.5rem;
}

label {
    &.input_div {
        height: 3.2em;

        span {
            position: absolute;
            padding: 0px 6px;
            font-size: 0.8em;
        }

        input {
            position: relative;
            margin-top: 15px;
            padding: 0px 6px;
            height: 2em;
        }
    }
}

.w25 {
    width: 25px;
}

.w30 {
    width: 30px;
}

.w40 {
    width: 40px;
}

.w60 {
    width: 60px;
}

.w100 {
    width: 100px;
}

.w125 {
    width: 125px;
}

.w150 {
    width: 150px;
}

.w200 {
    width: 220px;
}

.w300 {
    width: 300px;
}

.w500 {
    width: 500px;
}

.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fixed {
    position: fixed;
}

.nocenter {
    justify-content: unset !important;
}


::-webkit-scrollbar {
    background: var(--color_bg_fade_4x);
    width: 10px;
}

/* Style the scrollbar handle */
::-webkit-scrollbar-thumb {
    background: var(--color_bg_fade_4x);
    border-radius: 10px;
}


$color_1: #ff8700;
$color_2: #007a0c;
$color_3: #0028a0;
$color_4: #8d0101;
$color_5: #978100;
$color_6: #ff8700;

$color_8: #EE1212;
$color_9: #F77306;
$color_13: #A62DA1;
$color_10: #c9b905;
$color_11: #00985B;
$color_12: #3A68BE;
$color_7: #000000;

$player_1: $color_8;
$player_2: $color_11;
$player_3: $color_13;
$player_4: $color_10;
$player_5: $color_9;
$player_6: $color_12;
$player_7: $color_7;
$players: $player_1,
$player_2,
$player_3,
$player_4,
$player_5,
$player_6,
$player_7;

@for $i from 1 through 7 {
    $player: nth($players, $i);


    .p#{$i} {
        color: #{$player} !important;
    }

    .p#{$i}-bg {
        background-color: #{$player} !important;
    }

    .p#{$i}-fill {
        fill: #{$player} !important;
    }
}