$theme-color: #007bffa2;
$theme-color-opposite: #B70F39;

$gradient_blue: #6fb1daff;
$gradient_white: #def3ffff;

$ice_blue: #3bc0f9;
$ice_light_blue: #65cff7;
$ice_white:#e9f5f6;
$ice_white_blue: #cde4f3;
$ice_navy: #04245c;

// From schemecolor.com
$sc_blue: #9ED8F0;
$sc_water: #D4F1F9;
$sc_cerulean: #94C6E3;
$sc_iceberg: #75AEDC;
$sc_steel_blue: #3F7EB3;
$sc_iceberg_2: #6BA7CC;
$sc_blizzard_blue: #AEDBF0;
$sc_water: #CBF1FA;



$at_icerberg_dark: #81a3e1ff;
$at_title: #0e5c91db;
$at_blue: #007bffa2;

// Z-Index
$zindex_float: -1;
$zindex_top: 100;
$zindex_footer: 50;
$zindex_tutorial: 101;



$size-mobile: 850px;
$size-mobile-2: 650px;


// HEIGHT
$main_vh: 300px;
$main_vh_max: 85vh;
$footer_vh: 2rem;


//  Game Board settings


$game_nw_container_pad: 1rem;


$board_maxH: 440px;
$board_pad: 5px;

$cell_size: 40px;
$cell_margin: 1px;

$vertical_banner_width: 200px;
$vertical_banner_maxwidth: 15vw;