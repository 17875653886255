@import "../../variables";

footer {
    // z-index: $zindex_footer;
    position: relative;
    bottom: 0;
    width: 100vw;
    // height: min(0.75rem, 3vh);
    line-height: min(0.75rem, 3vh);
    font-size: min(0.65rem, 3vh);
    text-align: center;
    // margin-bottom: min(0.5rem, 3vh);

    .terms {
        cursor: pointer;
        font-weight: bold;

    }
}