@import "../../variables";


section.menu-terms {
    z-index: $zindex_top;
    background-color: var(--color_menu_resp_bg);
    width: 1000px;
    max-width: 95vw;
    height: 600px;
    max-height: calc(100vh - $footer_vh);
    border-radius: 1rem;

    .title {
        color: var(--color_dark_blue);
        height: 4rem;
        line-height: 4rem;
        font-family: "SnowtopCaps";
        font-size: 2rem;
        text-align: center;
    }

    .faq_content {
        height: calc(100% - 4rem - 3rem);
        width: 100%;
        overflow-y: auto;
        text-align: justify;

        .faq_question {
            font-size: 1em;
            width: 80%;
            margin: 0 auto;
        }

        .faq_answer {
            font-size: 0.8em;
            width: 80%;
            margin: 0 auto 1rem auto;
        }
    }

    .button {
        height: 1.5rem;
        line-height: 1.5rem;
        margin: auto auto;

    }


}