@font-face {
    font-family: "Poppins";
    src: local("Poppins"), url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "dotmatrix";
    src: local("dotmatrix"), url(./assets/fonts/DOTMATRI.TTF) format("truetype");
}

@font-face {
    font-family: "vt323";
    src: local("vt323"), url(./assets/fonts/VT323-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Mynerve";
    src: local("Mynerve"), url(./assets/fonts/Mynerve-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "SnowtopCaps";
    src: local("SnowtopCaps"), url(./assets/fonts/SnowtopCaps.ttf) format("truetype");
}

@font-face {
    font-family: "DavidLibre";
    src: local("DavidLibre"), url(./assets/fonts/DavidLibre-Regular.ttf) format("truetype");
}


body {
    height: 100vh;
    overflow-y: scroll;

    margin: 0;
    font-family: "Poppins";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}