@import "../../variables.scss";

.img_sound {
    position: fixed;
    top: 0;
    width: 3rem;
    margin: min(1rem, 2vh);
    z-index: calc($zindex_top + 1);
    cursor: pointer;

    &:hover {
        // box-shadow: 0 0 11px rgba(33, 33, 33, .2);
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .5));
    }
}